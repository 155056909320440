import { GlobalContext } from "@/pages/_app";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  HStack,
  Text,
  Divider,
  Box,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";

type BottomSheetProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

export const BottomSheet: React.FC<BottomSheetProps> = () => {
  const [globalData, setGlobalData] = useContext(GlobalContext);

  const { onOpen, isOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (globalData?.splitBillDrawer?.isOpen) {
      onOpen();
    } else if (!globalData?.splitBillDrawer?.isOpen) {
      onClose();
    }
  }, [globalData]);

  const handleOnClose = () => {
    setGlobalData((prevGlobalData: any) => ({
      ...prevGlobalData,
      splitBillDrawer: { isOpen: false },
    }));
  };

  return (
    <Drawer placement={"bottom"} onClose={handleOnClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent roundedTop={10}>
        <DrawerHeader borderBottomWidth="1px">Split the bill</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <Box>
            <Text fontSize={14} color={"gray.600"}>
              You can split the bill to only pay for your share
            </Text>
          </Box>
          <HStack justify={"space-between"} mt={5}>
            <Text>Pay a custom amount</Text>
            <Button
              backgroundColor={"black"}
              color={"white"}
              _hover={{
                backgroundColor: "blackAlpha.700",
              }}
              onClick={() =>
                setGlobalData((prevGlobalData: any) => ({
                  ...prevGlobalData,
                  splitBillDrawer: { isOpen: false },
                  customAmountDrawer: { isOpen: true },
                }))
              }
            >
              Select
            </Button>
          </HStack>
          <Divider my={5} borderColor={"gray.300"} />
          <HStack justify={"space-between"}>
            <Text>Divide the bill equally</Text>
            <Button
              backgroundColor={"black"}
              color={"white"}
              _hover={{
                backgroundColor: "blackAlpha.700",
              }}
              onClick={() =>
                setGlobalData((prevGlobalData: any) => ({
                  ...prevGlobalData,
                  splitBillDrawer: { isOpen: false },
                  divideBillDrawer: { isOpen: true },
                }))
              }
            >
              Select
            </Button>
          </HStack>
          <Divider my={5} borderColor={"gray.300"} />
          <HStack justify={"space-between"} mb={5}>
            <Text>Pay for your items</Text>
            <Button
              backgroundColor={"black"}
              color={"white"}
              _hover={{
                backgroundColor: "blackAlpha.700",
              }}
              onClick={() =>
                setGlobalData((prevGlobalData: any) => ({
                  ...prevGlobalData,
                  splitBillDrawer: { isOpen: false },
                  yourItemsDrawer: { isOpen: true },
                }))
              }
            >
              Select
            </Button>
          </HStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
