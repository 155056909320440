import { Flex, Text } from "@chakra-ui/react";

export const DevelopmentBar = () => {
  return (
    <Flex
      width={"100%"}
      height={"40px"}
      backgroundColor={"black"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"fixed"}
      zIndex={1}
    >
      <Text color={"white"}>Development Mode</Text>
    </Flex>
  );
};
