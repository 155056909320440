import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import { BottomSheet } from "../BottomSheet";
import { CustomAmountBottomSheet } from "../CustomAmountSheet";
import { DevelopmentBar } from "../DevBar";
import { YourItemsSheet } from "../YourItemsSheet";
import { DivideBillSheet } from "../DivideBillSheet";

interface LayoutProps extends FlexProps {
  children: ReactNode;
}

export const Layout = ({ children, ...rest }: LayoutProps) => (
  <>
    {process.env.NODE_ENV === "development" && <DevelopmentBar />}
    <BottomSheet />
    <CustomAmountBottomSheet />
    <YourItemsSheet />
    <DivideBillSheet />
    <Flex direction="column" {...rest}>
      {children}
    </Flex>
  </>
);
