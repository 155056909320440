import { GlobalContext } from "@/pages/_app";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  HStack,
  Text,
  Input,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

type CustomAmountSheetProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

export const CustomAmountBottomSheet: React.FC<CustomAmountSheetProps> = () => {
  const [globalData, setGlobalData] = useContext(GlobalContext);
  const [value, setValue] = useState("0");
  const [amount, setAmount] = useState(0);

  const { onOpen, isOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (globalData?.customAmountDrawer?.isOpen) {
      setAmount(globalData?.bill?.totalBill);
      onOpen();
    } else if (!globalData?.customAmountDrawer?.isOpen) {
      onClose();
    }
  }, [globalData]);

  const handleOnClose = () => {
    setGlobalData((prevGlobalData: any) => ({
      ...prevGlobalData,
      customAmountDrawer: { isOpen: false },
    }));
  };

  const handleOnConfirm = () => {
    setGlobalData((prevGlobalData: any) => ({
      ...prevGlobalData,
      bill: {
        ...prevGlobalData.bill,
        currentShare: Number(value) * 100,
        remaining: amount - Number(value) * 100,
      },
      customAmountDrawer: { isOpen: false },
    }));
  };

  return (
    <Drawer placement={"bottom"} onClose={handleOnClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent roundedTop={10}>
        <DrawerHeader>Pay a custom amount</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <Flex h="100%" direction={"column"} justify={"space-between"}>
            <Input
              h={50}
              placeholder="Amount"
              focusBorderColor="brand.500"
              borderStyle="dashed"
              type="number"
              value={value}
              min={0}
              onChange={(e) => setValue(e.target.value)}
            />
            <Flex direction={"column"} py={5} justifyContent={"flex-start"}>
              {Number(value) > 0 && (
                <HStack
                  justify={"space-between"}
                  fontSize={14}
                  fontWeight={"bold"}
                  color={"gray.600"}
                >
                  <Text>Left To Pay</Text>
                  <Text>
                    {globalData?.bill.currency}{" "}
                    {(amount / 100 - Number(value)).toFixed(2)}
                  </Text>
                </HStack>
              )}
              <HStack justify={"space-between"}>
                <Text>Your share</Text>
                <Text>
                  {globalData?.bill.currency}{" "}
                  {Number(value) ? Number(value).toFixed(2) : "0.00"}
                </Text>
              </HStack>
              <Text fontSize={14} color={"gray.600"}>
                Inclusive of all taxes and charges
              </Text>

              <HStack justify={"flex-end"} pt={5}>
                <Button
                  width={"100%"}
                  backgroundColor={"red.500"}
                  color={"white"}
                  _hover={{
                    backgroundColor: "red.700",
                  }}
                  onClick={() =>
                    setGlobalData((prevGlobalData: any) => ({
                      ...prevGlobalData,
                      customAmountDrawer: { isOpen: false },
                    }))
                  }
                >
                  Remove split
                </Button>
                <Button
                  width={"100%"}
                  backgroundColor={"brand.500"}
                  color={"black"}
                  _hover={{
                    backgroundColor: "brand.50",
                  }}
                  onClick={() => handleOnConfirm()}
                >
                  Confirm
                </Button>
              </HStack>
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
