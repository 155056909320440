import { GlobalContext } from "@/pages/_app";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  Text,
  Flex,
  HStack,
  Button,
  Divider,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { CustomItem } from "../CustomItem";

type CustomAmountSheetProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

type ItemType = {
  index: number;
  name: string;
  qty: number;
  total_qty: number;
};

export const DivideBillSheet: React.FC<CustomAmountSheetProps> = () => {
  const [globalData, setGlobalData] = useContext(GlobalContext);
  const [value, setValue] = useState("0");
  const [amount, setAmount] = useState(0);
  const [selectedItems, setSelectedItems] = useState<ItemType[]>([
    {
      index: 0,
      name: "Total people at your table",
      qty: 20,
      total_qty: 1,
    },
    {
      index: 1,
      name: "People you pay for",
      qty: 1,
      total_qty: 0,
    },
  ]);

  const { onOpen, isOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (globalData?.divideBillDrawer?.isOpen) {
      setAmount(globalData?.bill?.totalBill);
      onOpen();
    } else if (!globalData?.divideBillDrawer?.isOpen) {
      onClose();
    }
  }, [globalData]);

  const handleOnClose = () => {
    setGlobalData((prevGlobalData: any) => ({
      ...prevGlobalData,
      divideBillDrawer: { isOpen: false },
    }));
  };

  const handleOnConfirm = () => {
    setGlobalData((prevGlobalData: any) => ({
      ...prevGlobalData,
      bill: {
        ...prevGlobalData.bill,
        currentShare: Number(value) * 100,
        remaining: amount - Number(value) * 100,
      },
      divideBillDrawer: { isOpen: false },
    }));
  };

  const handleOnSelect = (item: ItemType, action: "add" | "remove") => {
    setSelectedItems((prevSelectedItems) => {
      const updatedItems = prevSelectedItems.map((selectedItem) => {
        if (selectedItem.name === item.name) {
          if (action === "add") {
            const newTotalQty =
              selectedItem.total_qty !== undefined
                ? Math.min(item.qty, selectedItem.total_qty + 1)
                : 1;
            return { ...selectedItem, total_qty: newTotalQty };
          } else if (action === "remove") {
            const newTotalQty =
              selectedItem.total_qty !== undefined
                ? Math.max(0, selectedItem.total_qty - 1)
                : 0;
            return { ...selectedItem, total_qty: newTotalQty };
          }
        }
        return selectedItem;
      });

      // Ensure selectedItems[1].qty matches selectedItems[0].total_qty
      if (updatedItems[0].name === "Total people at your table") {
        updatedItems[1].qty = updatedItems[0].total_qty;
      }

      // Ensure selectedItems[1].total_qty matches selectedItems[0].total_qty
      if (
        action === "remove" &&
        updatedItems[1].total_qty > updatedItems[0].total_qty
      ) {
        updatedItems[1].total_qty = updatedItems[0].total_qty;
      }

      // Calculate total amount based on updated selected items
      const totalAmount =
        (amount / updatedItems[0].total_qty) * updatedItems[1].total_qty;

      // // Update the amount state
      setValue((totalAmount / 100).toString());

      return updatedItems;
    });
  };

  return (
    <Drawer placement={"bottom"} onClose={handleOnClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent roundedTop={10}>
        <DrawerHeader>Divide the bill equally</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <Flex direction={"column"} py={5} justifyContent={"flex-start"}>
            {selectedItems.map((item, index) => (
              <CustomItem
                key={index}
                item={item}
                willSelect={true}
                onSelect={(value) =>
                  handleOnSelect(item, value as "add" | "remove")
                }
              />
            ))}
            <Divider my={3} borderColor={"gray.300"} />
            {Number(value) > 0 && (
              <HStack
                justify={"space-between"}
                fontSize={14}
                fontWeight={"bold"}
                color={"gray.600"}
              >
                <Text>Left To Pay</Text>
                <Text>
                  {globalData?.bill.currency}{" "}
                  {(amount / 100 - Number(value)).toFixed(2)}
                </Text>
              </HStack>
            )}
            <HStack justify={"space-between"}>
              <Text>Your share</Text>
              <Text>
                {globalData?.bill.currency}{" "}
                {Number(value) ? Number(value).toFixed(2) : "0.00"}
              </Text>
            </HStack>
            <Text fontSize={14} color={"gray.600"}>
              Inclusive of all taxes and charges
            </Text>

            <HStack justify={"flex-end"} pt={5}>
              <Button
                width={"100%"}
                backgroundColor={"red.500"}
                color={"white"}
                _hover={{
                  backgroundColor: "red.700",
                }}
                onClick={() => {
                  setGlobalData((prevGlobalData: any) => ({
                    ...prevGlobalData,
                    divideBillDrawer: { isOpen: false },
                  }));
                  setSelectedItems([
                    {
                      index: 0,
                      name: "Total people at your table",
                      qty: 20,
                      total_qty: 1,
                    },
                    {
                      index: 1,
                      name: "People you pay for",
                      qty: 1,
                      total_qty: 0,
                    },
                  ]);
                }}
              >
                Remove split
              </Button>
              <Button
                width={"100%"}
                backgroundColor={"brand.500"}
                color={"black"}
                _hover={{
                  backgroundColor: "brand.50",
                }}
                onClick={() => handleOnConfirm()}
              >
                Confirm
              </Button>
            </HStack>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
