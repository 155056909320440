import { GlobalContext, ItemType } from "@/pages/_app";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  Text,
  Button,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Item } from "../Product";

type CustomAmountSheetProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

export const YourItemsSheet: React.FC<CustomAmountSheetProps> = () => {
  const [globalData, setGlobalData] = useContext(GlobalContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItems, setSelectedItems] = useState<ItemType[]>([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (globalData?.yourItemsDrawer?.isOpen) {
      onOpen();
    } else if (!globalData?.yourItemsDrawer?.isOpen) {
      onClose();
    }
  }, [globalData]);

  useEffect(() => {
    const consolidatedItems = globalData?.bill.remainingItems.reduce(
      (result, item) => {
        const existingItem = result.find((i) => i.name === item.name);

        if (existingItem) {
          // If item with the same name exists, update quantity
          existingItem.qty += item.qty;
        } else {
          // If item with the same name does not exist, add it to the result
          result.push({
            name: item.name,
            price: item.price / item.qty,
            currency: item.currency,
            qty: item.qty,
            total: item.qty * (item.price / item.qty),
            total_qty: item.total_qty || 0,
          } as ItemType);
        }

        return result;
      },
      [] as ItemType[]
    );

    if (consolidatedItems !== undefined) {
      setSelectedItems(consolidatedItems);
    }
  }, [globalData]);

  const handleOnClose = () => {
    setGlobalData((prevGlobalData: any) => ({
      ...prevGlobalData,
      yourItemsDrawer: { isOpen: false },
    }));
  };

  const handleOnSelect = (item: ItemType, action: "add" | "remove") => {
    setSelectedItems((prevSelectedItems) => {
      const updatedItems = prevSelectedItems.map((selectedItem) => {
        if (selectedItem.name === item.name) {
          if (action === "add") {
            const newTotalQty =
              selectedItem.total_qty !== undefined
                ? Math.min(item.qty, selectedItem.total_qty + 1)
                : 1;
            return { ...selectedItem, total_qty: newTotalQty };
          } else if (action === "remove") {
            const newTotalQty =
              selectedItem.total_qty !== undefined
                ? Math.max(0, selectedItem.total_qty - 1)
                : 0;
            return { ...selectedItem, total_qty: newTotalQty };
          }
        }
        return selectedItem;
      });

      // Calculate total amount based on updated selected items
      const totalAmount = updatedItems.reduce((total, selectedItem) => {
        if (selectedItem.total_qty && selectedItem.total_qty > 0) {
          total += selectedItem.total_qty * selectedItem.price;
        }
        return total;
      }, 0);

      // Update the amount state
      setValue(totalAmount);

      return updatedItems;
    });
  };

  const handleOnConfirm = () => {
    setGlobalData((prevGlobalData: any) => {
      const totalBill = globalData?.bill.totalBill || 0;
      return {
        ...prevGlobalData,
        bill: {
          ...prevGlobalData.bill,
          currentShare: value,
          remaining: totalBill - value,
          remainingItems: selectedItems,
        },
        yourItemsDrawer: { isOpen: false },
      };
    });
  };

  return (
    <Drawer placement="bottom" onClose={handleOnClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent roundedTop={10}>
        <DrawerHeader>Pay for your items</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <Flex direction={"column"} pb={150}>
            {selectedItems.length > 0 ? (
              selectedItems.map((item, index) => (
                <Item
                  key={index}
                  item={item}
                  currency={globalData?.bill.currency || ""}
                  willSelect={true}
                  onSelect={(value) =>
                    handleOnSelect(item, value as "add" | "remove")
                  }
                />
              ))
            ) : (
              <Text>No items to display</Text>
            )}
          </Flex>
          <Flex
            position={"fixed"}
            w={"100%"}
            h={150}
            direction={"column"}
            py={5}
            pr={"50px"}
            justifyContent={"flex-start"}
            bottom={0}
            backgroundColor={"white"}
            borderTopWidth={1}
          >
            {value > 0 && (
              <HStack
                justify={"space-between"}
                fontSize={14}
                fontWeight={"bold"}
                color={"gray.600"}
              >
                <Text>Left To Pay</Text>
                <Text>
                  {globalData?.bill.currency}{" "}
                  {(globalData?.bill.totalBill
                    ? (globalData?.bill.totalBill - value) / 100
                    : 0
                  ).toFixed(2)}
                </Text>
              </HStack>
            )}
            <HStack justify={"space-between"}>
              <Text>Your share</Text>
              <Text>
                {globalData?.bill.currency} {(value / 100).toFixed(2)}
              </Text>
            </HStack>
            <Text fontSize={14} color={"gray.600"}>
              Inclusive of all taxes and charges
            </Text>

            <HStack>
              <Button
                width={"100%"}
                backgroundColor={"red.500"}
                color={"white"}
                _hover={{
                  backgroundColor: "red.700",
                }}
                onClick={() =>
                  setGlobalData((prevGlobalData: any) => ({
                    ...prevGlobalData,
                    yourItemsDrawer: { isOpen: false },
                  }))
                }
              >
                Cancel
              </Button>
              <Button
                width={"100%"}
                backgroundColor={"brand.500"}
                color={"black"}
                _hover={{
                  backgroundColor: "brand.50",
                }}
                onClick={() => handleOnConfirm()}
              >
                Confirm
              </Button>
            </HStack>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
