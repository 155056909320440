import { Flex, HStack, Box, Text } from "@chakra-ui/react";
import { Nunito_Sans } from "next/font/google";
import { FaUserAlt } from "react-icons/fa";

// Subsets are really important.
const nunito = Nunito_Sans({
  weight: ["400", "500"], // if single weight, otherwise you use array like [400, 500, 700],
  style: ["normal"], // if single style, otherwise you use array like ['normal', 'italic']
  subsets: ["latin"],
});

export const CustomItem = ({
  item,
  willSelect = false,
  onSelect,
}: {
  item: any;
  willSelect?: boolean;
  onSelect: (value: string) => void;
}) => {
  return (
    <Flex
      w={"100%"}
      justify={"space-between"}
      className={nunito.className}
      fontWeight={"500"}
      py={2}
      fontSize={15}
      color={"gray.600"}
    >
      <HStack>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          backgroundColor={"brand.500"}
          w={6}
          h={6}
          rounded={50}
        >
          <FaUserAlt size={10} color="black" />
        </Flex>
        <Box>
          <Text>{item.name}</Text>
        </Box>
      </HStack>

      <HStack>
        {willSelect && (
          <>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              backgroundColor={item.total_qty === 0 ? "gray.200" : "brand.500"}
              w={6}
              h={6}
              rounded={50}
              onClick={() => onSelect("remove")}
              cursor={"pointer"}
            >
              <Text textAlign={"center"} fontSize={12}>
                -
              </Text>
            </Flex>
            {item.total_qty || 0}
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              backgroundColor={
                item.total_qty === item.qty ? "gray.200" : "brand.500"
              }
              w={6}
              h={6}
              rounded={50}
              onClick={() => onSelect("add")}
              cursor={"pointer"}
            >
              <Text textAlign={"center"} fontSize={12}>
                +
              </Text>
            </Flex>
          </>
        )}
      </HStack>
    </Flex>
  );
};
